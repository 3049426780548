import { DxCombobox } from '@dvag/design-system-react';
import debounce from 'debounce';

import i18next from 'i18next';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AUTOSAVE_TIME_OUT } from 'utils/util';

import '../style.css';
import { getUeeLoadAddressValidation } from 'hooks/useLoadAddressValidationList';
import { ValidateAddress } from 'type/address';
import { MIN_SEARCH_TERM_LENGTH } from '../util';

const translationContext = {
  emptySearchText: i18next.t('combobox.noSearchResultsFoundForInput'),
  loadingText: i18next.t('combobox.searchResultsLoading'),
  preSearchConditionText: i18next.t('combobox.enterMinCharactersForSearchTerm', {
    minSearchTermLength: MIN_SEARCH_TERM_LENGTH,
  }),
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
};

type SearchOption<T> = { label: string; data: T };

interface SearchInputProps {
  country?: string;
  errormessage?: string;
  onSelectSearchOption: (searchOption: ValidateAddress | undefined) => void;
  isRequired?: boolean;
  label: string;
  id: string;
  isDisabled?: boolean;
  debounceTime?: number;
  presearchLetterCount?: number;
  value?: string;
  matchOptionValue?: (optionValue: string | undefined, data: ValidateAddress) => boolean;
  getLabel: (data: ValidateAddress) => string;
}

export const mapData = <T,>(data: T, getLabel: (item: T) => string): SearchOption<T> => ({
  label: getLabel(data),
  data,
});

type ProvidedValues<T> = {
  label: null;
  options: SearchOption<T>[];
}[];

type TermAndProvidedValues<T> = {
  term: string | undefined;
  providedValues: ProvidedValues<T> | undefined;
};

export const AddressSearchInput = ({
  errormessage,
  isDisabled,
  onSelectSearchOption,
  isRequired = false,
  label,
  id,
  debounceTime = AUTOSAVE_TIME_OUT,
  presearchLetterCount = MIN_SEARCH_TERM_LENGTH,
  value,
  matchOptionValue,
  getLabel,
  country,
}: SearchInputProps) => {
  const getLabelRef = useRef(getLabel);
  const optionHasValueRef = useRef(matchOptionValue);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(
    optionHasValueRef.current ? '' : value,
  );
  const [searchTerm, setSearchTerm] = useState(optionHasValueRef.current ? '' : value);

  const search = useRef<TermAndProvidedValues<ValidateAddress>>({
    term: value,
    providedValues: undefined,
  });
  const provideValuesRef =
    React.useRef<(searchItems: ProvidedValues<ValidateAddress>) => void>(undefined);
  const debounceSetSearchTerm = useMemo(
    () =>
      debounce((searchVal: string) => {
        search.current.term = searchVal;
        setDebouncedSearchTerm(searchVal);
      }, debounceTime),
    [debounceTime],
  );

  const valueTransformedToLabel = useRef(false);

  getUeeLoadAddressValidation(country)(
    debouncedSearchTerm,
    useCallback((data) => {
      const providedValues = data?.length
        ? [
            {
              label: null,
              options: data.map((p) => mapData(p as ValidateAddress, getLabelRef.current)),
            },
          ]
        : [];

      provideValuesRef.current?.(providedValues);
      search.current.providedValues = providedValues;
      if (!valueTransformedToLabel.current && optionHasValueRef.current && data?.length) {
        valueTransformedToLabel.current = true;
        const selectedItem = providedValues[0]?.options.find(
          (item) =>
            optionHasValueRef.current && optionHasValueRef.current(search.current?.term, item.data),
        );
        search.current.term = '';
        setSearchTerm(selectedItem?.label ?? '');
      }
    }, []),
  );

  const onSearch = useCallback(
    (e: Event) => {
      const { detail } = e as CustomEvent;
      if (!detail.reason) {
        return;
      }
      if (!provideValuesRef.current) {
        provideValuesRef.current = detail.provideValues;
      }

      if (search.current && detail.reason === 'clear') {
        setSearchTerm('');
        onSelectSearchOption(undefined);
        setDebouncedSearchTerm(undefined);
        return;
      }
      if (search.current && detail.reason === 'focus') {
        detail.provideValues(search.current.providedValues);
        return;
      }
      if (detail.value === search.current?.term) {
        detail.provideValues(search.current?.providedValues);
        return;
      }
      debounceSetSearchTerm(detail.value);
    },
    [debounceSetSearchTerm, onSelectSearchOption],
  );

  useEffect(() => {
    const addressSearchInput = document.querySelector(`#${id}`);
    addressSearchInput?.addEventListener('search', onSearch);
    return () => addressSearchInput?.removeEventListener('search', onSearch);
  }, [id, onSearch]);

  return (
    <DxCombobox
      errormessage={errormessage}
      size="m"
      value={searchTerm}
      disabled={isDisabled}
      onSelectSearchOption={(e) => {
        onSelectSearchOption(e.detail);
      }}
      onInputChange={(e: CustomEvent) => {
        setSearchTerm(e.detail?.value);
        if (!e.detail) {
          onSelectSearchOption(undefined);
          setDebouncedSearchTerm(undefined);
        }
      }}
      label={label}
      required={isRequired}
      id={id}
      data-testid={id}
      presearchlettercount={presearchLetterCount}
      emptysearchtext={translationContext.emptySearchText}
      loadingtext={translationContext.loadingText}
      presearchconditiontext={translationContext.preSearchConditionText}
      placeholder={translationContext.inputDefaultPlaceholder}
    />
  );
};
