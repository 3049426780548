import { DxDropdownOption } from '@dvag/design-system-react';
import React, { useMemo, ReactElement } from 'react';
import { getUniqueListBy } from 'form/util';
import { dialCodeList } from 'utils/countries';

export type contactMemoizedDropdownOptionListType = {
  dialCodeListMemoized: ReactElement[];
};

export const useContactDialCodeOptionList = (): contactMemoizedDropdownOptionListType => {
  const dialCodeListMemoized = useMemo(
    () =>
      getUniqueListBy(dialCodeList, 'value')?.map(({ value, label }) => (
        <DxDropdownOption key={`${value}-${label}`} value={value} label={label} />
      )),
    [],
  );

  return {
    dialCodeListMemoized,
  };
};
