import React, { ReactElement } from 'react';

import { DxText, DxCardContent } from '@dvag/design-system-react';

import './style.css';

interface FormHeaderProps {
  subtitleText: string;
  subtitleIcon: ReactElement;
}

export const FormHeader = ({ subtitleText, subtitleIcon }: FormHeaderProps) => (
  <div className="flex row align-center">
    <DxCardContent className="pd_form-subtitle-container flex justify-start row align-center">
      {subtitleIcon && <div className="pd_form_subtitle_icon_container">{subtitleIcon}</div>}
      <DxText type="Infotext-small" color="gray-66">
        {subtitleText}
      </DxText>
    </DxCardContent>
  </div>
);
