import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DxContainer, DxGrid, DxSlider, DxText } from '@dvag/design-system-react';

import i18next from 'i18next';

import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import * as yup from 'yup';
import { useCreateOrUpdateSaverProfile } from 'hooks/useCreateOrUpdateSaverProfile';

import { route } from 'config/route';
import { buildPath } from 'service/buildPath';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { SaverFieldType, SavingProfileFormValues, SavingTypeFormProps } from 'type/savingType';
import { CardList } from 'utils/CardList';
import { savingTypeUrl } from 'utils/savingTypeUrl';

import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';

import { useContinuation } from 'utils/useContinuation';
import { SavingTypeCard } from '../SavingTypeCard/SavingTypeCard';
import { SavingTypeCardStart } from '../SavingTypeCard/SavingTypeCardStart';
import { SavingTypeCheckedCard } from '../SavingTypeCard/SavingTypeCheckedCard';
import { SavingTypeFooter } from '../SavingTypeFooter';

import { defaultSavingTypeValues } from '../../defaultSavingTypeValues';
import '../../style.css';
import { SavingTypeHeader } from '../SavingTypeHeader';
import { Tick } from '../Tick';

type SavingStepType = keyof typeof savingTypeUrl;
const savingStepTypes = Object.keys(savingTypeUrl);
const pagesWithSlider: string[] = [savingTypeUrl.gegenwaertig.url, savingTypeUrl.gewuenscht.url];

const subtitleTextMap: Record<string, string> = {
  selectAttributes: i18next.t('savingType.selectAttributes'),
  whatDistinguishesTypes: i18next.t('savingType.whatDistinguishesTypes'),
  currentType: i18next.t('savingType.currentType'),
  desiredType: i18next.t('savingType.desiredType'),
};

export const SavingTypeForm = ({ savingProfile }: SavingTypeFormProps) => {
  const { step: currentStep, householdId } = useParams();
  if (!savingStepTypes.includes(currentStep || '')) {
    throw Error('Not Found', { cause: 404 });
  }
  const createOrUpdateSaverProfile = useCreateOrUpdateSaverProfile(householdId);
  const showSlider = (step: SavingStepType) => pagesWithSlider.includes(step);
  const getSubtitle = (step: SavingStepType) => subtitleTextMap[savingTypeUrl[step].title];

  const topSubtitle = (step: SavingStepType) => {
    if (!showSlider(step)) return getSubtitle(step);
    return null;
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const showElement = (condition: boolean) => (condition ? { display: ' ' } : { display: 'none' });

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        saverProfileCurrent: yup.number(),
        saverProfileDesired: yup.number(),
        hasAsset: yup.boolean(),
        hasConsumerLoan: yup.boolean(),
        hasConsumption: yup.boolean(),
        hasDebt: yup.boolean(),
        hasDiscipline: yup.boolean(),
        hasDuePaymentNotice: yup.boolean(),
        hasOverdraft: yup.boolean(),
        hasPlusMinusZero: yup.boolean(),
        hasRevenueInterest: yup.boolean(),
        hasSavingBook: yup.boolean(),
        hasStrategy: yup.boolean(),
        hasTwoAccountModel: yup.boolean(),
        createdAt: yup.string().optional(),
        updatedAt: yup.string().optional(),
      }),
    [],
  );
  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);
  const { register, setValue, flushChanges, getValues } = useAutosave<SavingProfileFormValues>({
    data: savingProfile,
    defaultData: defaultSavingTypeValues,
    createResource: createOrUpdateSaverProfile.mutateAsync,
    updateResource: createOrUpdateSaverProfile.mutateAsync,
    onIsDirtyChange: (isDirtyParam) => {
      if (!isDirtyParam) {
        getCanContinueHandler();
      }
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: setIsSubmitting,
    resourceSchema,
  });
  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  const navigateNext = () => {
    if (!householdId) return;
    if (currentStep === savingTypeUrl.gewuenscht.url) {
      handleNavigateToPath(savingTypeUrl.gewuenscht.nextUrl, householdId);
    } else {
      const path = buildPath(route.savingType, {
        householdId,
        step: savingTypeUrl[currentStep as SavingStepType].nextUrl,
      });
      navigate(path);
    }
  };

  return (
    <>
      <SavingTypeHeader subtitle={topSubtitle(currentStep as SavingStepType)} />
      <DxContainer color="transparent" type="page">
        <DxContainer
          color="transparent"
          className="savings-type-grid-container"
          style={{ overflow: 'hidden' }}
        >
          <form>
            <DxGrid
              mq3="4-4-4"
              mq1="12/*"
              style={showElement(currentStep === savingTypeUrl.start.url)}
              data-showform={`${currentStep === savingTypeUrl.start.url}` as unknown as boolean}
              data-testid="saving-type-form"
            >
              {CardList.map((card) => (
                <SavingTypeCardStart
                  card={card}
                  savingTypeId={`${card.id}-${householdId}`}
                  key={card.id}
                  currentStep={currentStep}
                />
              ))}
            </DxGrid>
            <DxGrid
              mq3="4-4-4"
              mq1="12/*"
              style={showElement(currentStep === savingTypeUrl.merkmale.url)}
              data-showform={`${currentStep === savingTypeUrl.merkmale.url}` as unknown as boolean}
            >
              {CardList.map((card) => (
                <SavingTypeCard
                  card={card}
                  savingTypeId={`${card.id}-${householdId}`}
                  key={card.id}
                  currentStep={currentStep}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                />
              ))}
            </DxGrid>
            <DxGrid
              mq3="4-4-4"
              mq1="12/*"
              style={showElement(
                currentStep === savingTypeUrl.gegenwaertig.url ||
                  currentStep === savingTypeUrl.gewuenscht.url,
              )}
              data-showform={
                `${currentStep === savingTypeUrl.gegenwaertig.url ||
                currentStep === savingTypeUrl.gewuenscht.url}` as unknown as boolean
              }
            >
              {CardList.map((card) => (
                <SavingTypeCheckedCard
                  card={card}
                  savingTypeId={`${card.id}-${householdId}`}
                  key={card.id}
                  currentStep={currentStep}
                  getValues={getValues}
                  register={register}
                />
              ))}
            </DxGrid>

            <DxContainer
              color="transparent"
              className="slider-container"
              style={showElement(
                currentStep === savingTypeUrl.gegenwaertig.url ||
                  currentStep === savingTypeUrl.gewuenscht.url,
              )}
            >
              <DxText type="ps" color="gray-83">
                {getSubtitle(currentStep as SavingStepType)}
              </DxText>

              <div className="slider-label">
                {CardList.map((card) => (
                  <DxText type="its" key={card.id}>
                    {card.title}
                  </DxText>
                ))}
              </div>

              <div className="saving-type-slider">
                <div style={showElement(currentStep === savingTypeUrl.gewuenscht.url)}>
                  <Tick
                    percent={getValues(SaverFieldType.saverProfileCurrent) as number | undefined}
                  />
                </div>
                <DxSlider
                  data-testid="current-saving-type-slider"
                  id="saving-type-slider"
                  style={showElement(currentStep === savingTypeUrl.gegenwaertig.url)}
                  min={0}
                  max={100}
                  step={1}
                  showvaluelabel={false}
                  showminmaxlabel={false}
                  {...register(SaverFieldType.saverProfileCurrent)}
                />
                <DxSlider
                  data-testid="desired-saving-type-slider"
                  id="saving-type-slider"
                  style={showElement(currentStep === savingTypeUrl.gewuenscht.url)}
                  min={0}
                  max={100}
                  step={1}
                  showvaluelabel={false}
                  showminmaxlabel={false}
                  {...register(SaverFieldType.saverProfileDesired)}
                />
              </div>
            </DxContainer>
          </form>
          <SavingTypeFooter
            handleBack={
              currentStep === savingTypeUrl.merkmale.url
                ? getContinuationHandler(() => navigateBack())
                : () => navigateBack()
            }
            handleContinue={
              currentStep === savingTypeUrl.gewuenscht.url
                ? getContinuationHandler(() => navigateNext())
                : () => navigateNext()
            }
          />
          <UnsavedOrPendingModal showPending={continuation !== undefined && isSubmitting} />
        </DxContainer>
      </DxContainer>
    </>
  );
};
