import React from 'react';
import { DxNotificationToast, DxText } from '@dvag/design-system-react';
import { useNotificationContext } from './NotificationContext';
import { NotificationType } from './types';

const CLOSE_TIMEOUT = 5;

const iconMap: Record<NotificationType, string> = {
  default: 'error',
  success: 'check-kreis-thin-outlined',
  error: 'error',
};


export const NotificationComponent = () => {
  const { data, hide } = useNotificationContext();

  const type = data?.type || 'default';
  const title = data?.title || '';
  const description = data?.description || '';

  return (
    <DxNotificationToast
      id="notification-toast"
      data-testid="notification-toast"
      visible={`${!!data}` as unknown as boolean}
      closeafter={CLOSE_TIMEOUT}
      icon={iconMap[type]}
      type={type}
      titlestring={title}
      onCloseNotification={hide}
    >
      {description && <DxText>{description}</DxText>}
    </DxNotificationToast>
  );
};
