import { useParams } from 'react-router-dom';
import { ReactElement } from 'react';

import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { DxButton, DxCard, DxCardContent, DxContainer } from '@dvag/design-system-react';

import i18next from 'i18next';

import { route } from 'config/route';
import { buildPath } from 'service/buildPath';
import { checkIcon } from 'utils/util';
import { FormHeader } from './FormHeader/FormHeader';

type CardContainerType = {
  firstName?: string;
  lastName?: string;
  index?: number;
  children: ReactElement;
  id?: string;
  handleContinuation?: (navigateToEdit: () => void) => () => void;
};

export const CardContainer = ({
  firstName,
  lastName,
  index,
  children,
  id,
  handleContinuation,
}: CardContainerType) => {
  const { householdId } = useParams();

  const navigateToEdit = () => {
    navigateToMicroApp(
      'person',
      buildPath(route.person, { householdId: householdId as string, personId: id! }),
    );
  };
  return (
    <DxContainer color="white" style={{ marginTop: index !== 0 ? '24px' : '' }}>
      <DxCard className="pd_basicData-container" data-testid={`person-${index}`}>
        <FormHeader firstName={firstName} lastName={lastName} index={index} />
        <DxCardContent className="pd_basicData-form-container" style={{ padding: '24px' }}>
          {children}
        </DxCardContent>
        {id && (
          <div className="flex justify-end edit-person">
            <DxButton
              id="edit-person"
              data-testid="edit-person"
              label={i18next.t('general.editPerson')}
              type="text"
              icon={checkIcon('bearbeiten')}
              iconposition="left"
              onClick={() => (handleContinuation ? handleContinuation(navigateToEdit)() : '')}
            />
          </div>
        )}
      </DxCard>
    </DxContainer>
  );
};
