import { useCallback, useReducer } from 'react';

import { reducer } from 'reducer';

import type {
  PersonalDataAction,
  PersonalDataActionPayload,
  PersonalDataState,
  PersonalDataStateSection,
} from 'reducer';

export const defaultBody: PersonalDataStateSection = {
  isSuccess: false,
  isValid: true,
  isDirty: false,
  isSubmitting: false,
};

export const defaultState: PersonalDataState = {
  basicData: defaultBody,
  address: defaultBody,
  contact: defaultBody,
  profession: defaultBody,
  tax: defaultBody,
  bankDetails: defaultBody,
};

interface PersonalDataReducer {
  formState: PersonalDataState;
  dispatch: React.Dispatch<PersonalDataAction>;
  updateFormState: (actionType: string, formState: PersonalDataActionPayload) => void;
}

export const usePersonalDataReducer = (): PersonalDataReducer => {
  const [formState, dispatch] = useReducer(reducer, defaultState);

  const updateFormState = useCallback(
    (actionType: string, newFormState: PersonalDataActionPayload) => {
      if (newFormState) {
        dispatch({
          type: actionType,
          payload: newFormState,
        });
      }
    },
    [],
  );

  return { formState, dispatch, updateFormState };
};
