import {
  DxButton,
  DxDropdown,
  DxDropdownOption,
  DxText,
  DxTextInput,
} from '@dvag/design-system-react';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import {
  CreateOrUpdateResource,
  DeleteResource,
} from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { defaultEmailData, emailKeyList, keyEmailSectionName } from 'form/util';
import { validateEmail } from 'form/validation';

import { useCreateOrUpdateCommunicationChannel } from 'hooks/useCreateOrUpdateCommunicationChannel';
import { useDeleteCommunicationChannel } from 'hooks/useDeleteCommunicationChannel';
import 'style.css';
import { style } from 'styleConfig';
import { IEmailForm, TFormValueList } from 'type/contact';
import { FormType, FormTypes, SecondaryFormType, TriggerValidationValue } from 'utils/fieldList';
import { ContinutationRequest } from 'utils/useContinuation';
import { checkIcon, emailTypeOptionList, xl } from 'utils/util';
import { checkIsMobile } from 'utils/windowSize';
import { isDeleteDisabled, SECOND_SECTION_INDEX } from './common';

type EmailSectionProps = {
  personId: string | undefined;
  data: IEmailForm[];
  onFormDirtyStatusChanged: (isDirty: boolean) => void;
  onFormValidStatusChanged: (isValid: boolean) => void;
  onFormSubmittingStatusChanged: (isSubmitting: boolean) => void;
  onFormSaved: () => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  display: string;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
};

const translation = {
  email: i18next.t('contact.email'),
  emailType: i18next.t('contact.emailType'),
  addEmail: i18next.t('contact.addEmail'),
  selectDefaultPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
};

export const EmailSection = ({
  personId,
  data,
  onFormValidStatusChanged,
  onFormDirtyStatusChanged,
  onFormSubmittingStatusChanged,
  onFormSaved,
  getCanContinueHandler,
  onContinuationRequest,
  setTriggerValidationForms,
}: EmailSectionProps) => {
  const resourceSchema = useMemo(
    () =>
      yup.object({
        value: yup
          .string()
          .nullable()
          .required(i18next.t('general.mandatoryField'))
          .test(
            'validateEmail',
            i18next.t('contact.errorMessage'),
            (x) => validateEmail(x) === true,
          ),
      }),
    [],
  );

  const deleteCommunicationChannel = useDeleteCommunicationChannel(personId);
  const createOrUpdateCommunicationChannel = useCreateOrUpdateCommunicationChannel(personId);

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, flushChanges, remove, append, triggerValidation, resources, getAllResources } =
    useAutosave<IEmailForm>({
      data,
      defaultData: defaultEmailData,
      createResource:
        createOrUpdateCommunicationChannel.mutateAsync as CreateOrUpdateResource<IEmailForm>,
      updateResource:
        createOrUpdateCommunicationChannel.mutateAsync as CreateOrUpdateResource<IEmailForm>,
      onIsValidChange: (isValidParam) => {
        onFormValidStatusChanged(isValidParam);
      },
      onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
        if (isSubmitSuccessfulParam) onFormSaved();
      },
      onIsDirtyChange: (isDirtyParam) => {
        setIsDirty(isDirtyParam);
        onFormDirtyStatusChanged(isDirtyParam);
        onCanContinueChange(!isDirtyParam);
      },
      onIsSubmittingChange: (isSubmittingParam) => {
        setIsSubmitting(isSubmittingParam);
        onFormSubmittingStatusChanged(isSubmittingParam);
      },
      resourceSchema,
      deleteResource:
        deleteCommunicationChannel.mutateAsync as unknown as DeleteResource<IEmailForm>,
    });

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  const isMobile = checkIsMobile();

  useEffect(() => {
    setTriggerValidationForms(
      triggerValidation as TriggerValidationValue,
      FormType.contact,
      'email',
    );
  }, [setTriggerValidationForms, triggerValidation]);

  const onAppend = () => {
    append(defaultEmailData, { shouldFocus: false });
  };

  return (
    <div
      className="pd_email-content-container"
      style={{ width: 'inherit', height: 'auto', clear: 'right' }}
    >
      <DxText color="headline" type="Paragraph-Standard" style={style.mainTitle}>
        {translation.email}
      </DxText>
      <form
        data-testid="email-section"
        style={{ paddingTop: '16px', clear: 'right' }}
        className="pd_contact-email-section"
      >
        {resources &&
          resources.map((item, index) => {
            register(`${keyEmailSectionName}.${index}.id`);
            return (
              <div
                key={item.id}
                className="pd_contact-email_container"
                style={{
                  gridTemplateColumns: `${style.contactEmailColumnGridTemplate} min-content`,
                }}
              >
                <DxDropdown
                  size="m"
                  placeholder={translation.selectDefaultPlaceholder}
                  required
                  className="pd_contact_type"
                  style={{ whiteSpace: 'nowrap' }}
                  data-testid={`pd_emailType-${index}`}
                  id={`pd_emailType-${index}`}
                  label={translation.emailType}
                  clearable={false}
                  {...register('type', index)}
                >
                  {emailTypeOptionList?.map(({ value, label }) => (
                    <DxDropdownOption
                      key={`${item.id}-${value}-${label}`}
                      value={value}
                      label={label}
                    />
                  ))}
                </DxDropdown>
                <DxTextInput
                  nominmaxlabel
                  size="m"
                  placeholder={translation.inputDefaultPlaceholder}
                  className="pd_contact_value"
                  data-testid={`pd_email-${index}`}
                  id={`pd_email-${index}`}
                  maxlength={xl}
                  required
                  label={translation.email}
                  {...register('value', index)}
                />
                <DxButton
                  type="text"
                  disabled={isDeleteDisabled(
                    index,
                    getAllResources() as TFormValueList,
                    emailKeyList,
                    isSubmitting || isDirty,
                    SECOND_SECTION_INDEX,
                  )}
                  className="pd_contact_delete"
                  onClick={() => remove(index)}
                  data-testid={`pd_deleteEmailSection-${index}`}
                  id={`pd_deleteEmailSection-${index}`}
                  icon={checkIcon('loeschen')}
                  theme="destructive"
                />
              </div>
            );
          })}
      </form>
      <DxButton
        type="text"
        className="pd_contact_addNewSection"
        data-testid="pd_addEmailSection"
        style={{ float: 'right', margin: isMobile ? '0 0 32px 0' : '24px 0 32px 0' }}
        id="pd_addEmailSection"
        disabled={getAllResources().some((p) => p.id === '' || p.id === undefined)}
        onClick={() => onAppend()}
        label={translation.addEmail}
        icon={checkIcon('plus-kreis')}
      />
    </div>
  );
};
